<template>
  <div>
    <b-row class="align-items-end">
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <SearchTextInput
          v-model="searchTicketIdValue"
          label="Search Ticket No."
          placeholder="Search Ticket No."
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <SearchTextInput
          v-model="searchSubjectValue"
          label="Search Subject"
          placeholder="Search Subject"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <!-- <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <FilterMultipleSelect
          v-model="searchUserGroupValue"
          label="Group"
          placeholder="Group"
          label-option="name"
          return-value="id"
          :options="userGroup"
          @input="fetchFilter()" />
      </b-col> -->
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <FilterDropdown
          v-model="searchIncidentValue"
          label="Ticket Type"
          placeholder="Ticket Type"
          label-option="name"
          return-value="id"
          :options="ticketType"
          @input="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <FilterDropdown
          v-model="searchPriorityValue"
          label="Priority"
          placeholder="Priority"
          label-option="label"
          return-value="value"
          :options="priorityOption"
          @input="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <DatePickerFilter
          v-model="startDateValue"
          label="Start Date" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <DatePickerFilter
          v-model="endDateValue"
          label="End Date" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <SearchTextInput
          v-model="searchCustomerNameValue"
          label="Search Customer Name"
          placeholder="Search Customer Name"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <SearchTextInput
          v-model="searchPhoneNumberValue"
          label="Search Phone Number"
          placeholder="Search Phone Number"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <SearchTextInput
          v-model="searchTransactionValue"
          label="Search Transaction , Contact ID"
          placeholder="Search Transaction, Contact ID"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <SearchTextInput
          v-model="searchProgressByValue"
          label="Search Progress By"
          placeholder="Search Progress By"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <FilterDropdown
          v-model="searchStatusValue"
          label="Status"
          placeholder="Status"
          label-option="label"
          return-value="value"
          :options="statusOptions"
          @input="fetchFilter()" />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-2"
        variant="primary"
        @click="fetchFilter()">
        <feather-icon
          icon="SearchIcon"
          class="mr-50 mr-md-0 mr-lg-0 mr-xl-50"
        />
        <span class="align-middle">Search</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex'
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'
import DatePickerFilter from '@/components/Filter/DatePickerFilter.vue'
// import FilterMultipleSelect from '@/components/Filter/FilterMultipleSelect.vue'

export default {
  components: {
    DatePickerFilter,
    SearchTextInput,
    FilterDropdown
    // FilterMultipleSelect
  },
  props: {
    ticketType: {
      type: Array,
      default: () => ([])
    },
    userGroup: {
      type: Array,
      default: () => ([])
    },
    searchIncident: {
      type: [String, Number],
      default: null
    },
    searchPriority: {
      type: String,
      default: null
    },
    searchSubject: {
      type: String,
      default: null
    },
    searchStatus: {
      type: String,
      default: null
    },
    searchCustomerName: {
      type: String,
      default: null
    },
    searchPhoneNumber: {
      type: String,
      default: null
    },
    searchUserGroup: {
      type: Array,
      default: () => ([])
    },
    searchTransaction: {
      type: String,
      default: null
    },
    searchProgressBy: {
      type: String,
      default: null
    },
    searchTicketId: {
      type: String,
      default: null
    },
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      incidentOptions: [
        { label: 'A', value: 'A1' },
        { label: 'B', value: 'B1' }
      ],
      priorityOption: [
        { label: 'Low', value: 'low' },
        { label: 'Medium', value: 'medium' },
        { label: 'High', value: 'high' }
      ],
      statusOptions: [
        { label: 'Open', value: 'open' },
        { label: 'Closed', value: 'closed' },
        { label: 'Void', value: 'void' },
        { label: 'Over Due', value: 'over Due' }
      ]
    }
  },
  computed: {
    searchIncidentValue: {
      get () {
        return this.searchIncident
      },
      set (val) {
        this.$emit('update:search-incident', val)
      }
    },
    searchPriorityValue: {
      get () {
        return this.searchPriority
      },
      set (val) {
        this.$emit('update:search-priority', val)
      }
    },
    searchStatusValue: {
      get () {
        return this.searchStatus
      },
      set (val) {
        this.$emit('update:search-status', val)
      }
    },
    searchCustomerNameValue: {
      get () {
        return this.searchCustomerName
      },
      set (val) {
        this.$emit('update:search-customer-name', val)
      }
    },
    searchProgressByValue: {
      get () {
        return this.searchProgressBy
      },
      set (val) {
        this.$emit('update:search-progress-by', val)
      }
    },
    searchPhoneNumberValue: {
      get () {
        return this.searchPhoneNumber
      },
      set (val) {
        this.$emit('update:search-phone-number', val)
      }
    },
    searchUserGroupValue: {
      get () {
        return this.searchUserGroup
      },
      set (val) {
        this.$emit('update:search-user-group', val)
      }
    },
    searchTicketIdValue: {
      get () {
        return this.searchTicketId
      },
      set (val) {
        this.$emit('update:search-ticket-id', val)
      }
    },
    searchTransactionValue: {
      get () {
        return this.searchTransaction
      },
      set (val) {
        this.$emit('update:search-transaction', val)
      }
    },
    searchSubjectValue: {
      get () {
        return this.searchSubject
      },
      set (val) {
        this.$emit('update:search-subject', val)
      }
    },
    startDateValue: {
      get () {
        return this.startDate
      },
      set (val) {
        this.$emit('update:start-date', val)
      }
    },
    endDateValue: {
      get () {
        return this.endDate
      },
      set (val) {
        this.$emit('update:end-date', val)
      }
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profileData: 'User/user',
    })
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
